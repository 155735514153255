.setting {
    padding: 100px;
    .MuiTextField-root{
        width: 100%;
    }
}
.settingSubmit {
    text-align: right;
    .MuiButton-outlined{
        margin-top:35px
    }
}
.settingPapper {
    padding: 20px;
}

.sLangDropdown{
    width: 100%;
}

@media only screen and (max-width: 500px) { 
    .setting {
        padding: 15px;
    }
}

.addSensors{
    margin-top: 50px;
    text-align: center;
    .MuiSvgIcon-root{
        font-size: 100px;
    }
}
#addPower{
    background-color: white;
    color: black;
    font-size: 20px;
}

.MuiTableCell-body {
    color: black !important;
}
.MuiTableCell-root {
    border: 1px solid white;
    // border-right: 1px solid white;
}
.MuiTableCell-head {
    color: white;
    background: black;
}

.MuiTablePagination-root {
    border: 1px solid white;
}

